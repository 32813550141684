* {
  margin: 0;
  padding: 0;
}

body {
  background-color: #000;
  color: white;
  font-family: "Inter", sans-serif;
}

a {
  color: #fff;
  text-decoration: none;
}

.container {
  width: 100%;
  margin: 0 auto;
  overflow: clip;
}

/* Header */

.menu {
  position: sticky;
  top: 0;
  z-index: 100;
  padding-top: 0.5em;
}

.header {
  color: #fff;
  margin: 2rem auto;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  width: 60%;
  align-items: first baseline;
  font-weight: 400;
}

/* Logo */
.header .logo {
  min-width: 120px;
  opacity: 0.2;
}

.nav ul,
.contact ul {
  list-style-type: none;
  display: flex;
  justify-content: flex-end;
}

.header .logo {
  transition: opacity 1s cubic-bezier(0.215, 0.61, 0.355, 1);
  opacity: 0.2;
}

.nav ul li,
.contact ul li {
  transition: opacity 1s cubic-bezier(0.215, 0.61, 0.355, 1);
  margin: 0px 0px 0px 30px;
  opacity: 0.2;
}

.header .logo:hover,
.nav ul li:hover,
.contact ul li:hover {
  opacity: 1;
  cursor: pointer;
}

.header .scrolled.logo,
.header .scrolled ul li {
  transition: opacity 1.4s cubic-bezier(0.215, 0.61, 0.355, 1);
  opacity: 1;
}

.header .scrolled.logo:hover,
.scrolled ul li:hover,
.scrolled ul li:hover {
  opacity: 0.2;
  cursor: pointer;
}

/* Intro Section */

.intro-container {
  width: 60%;
  margin: 20% auto;
}

.intro {
  display: flex;
  min-height: 100vm;
  font-size: 60px;
  margin-right: 10%;
  opacity: 0;
  transform: translateX(120px);
  transition: opacity 1.5s ease, transform 1.5s ease;
}

.intro.visible {
  opacity: 1;
  transform: translateY(0);
}

#intro-text {
  color: #fff;
  font-weight: 300;
}

/* History Section */

.history-container {
  display: flex;
  margin: 20rem auto;
  width: 60%;
}

.history-container p {
  color: #fff;
  font-weight: 300;
  font-weight: 400;
  font-size: 32px;
}

.history {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 2s ease, transform 2s ease;
}

.history.visible {
  opacity: 1;
  transform: translateY(0);
}

.history-container p {
  margin-top: 0px;
  margin-bottom: 1em;
}

.history-container a {
  opacity: 0.7;
}

.history-container .col-2 {
  font-size: 16px;
  margin-left: 15%;
  width: 55%;
}
.history-container .col-2 .heading {
  font-size: 12px;
  opacity: 0.4;
  font-weight: 400;
  margin-bottom: 0.7em;
}

.history-container .col-2 .heading:not(:first-child) {
  margin-top: 4.7rem;
}

.history-container .col-2 .role {
  margin-bottom: 1.2em;
}

.history-container .col-2 .role-time {
  margin-top: 0.2em;
  opacity: 0.9;
  font-size: 12px;
}

.circle {
  display: block;
  background: radial-gradient(
    circle,
    rgba(233, 120, 108, 1) 0%,
    rgba(255, 255, 255, 0) 20%
  );
  border-radius: 50%;
  opacity: 0.7;
  margin: 0;
  /* padding: 2em; */
  z-index: -1;
}

.arrow {
  margin-top: 6rem;
  position: relative;
  float: right;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transform: translateY(-320px);
  transition: opacity 1.5s ease, transform 1.5s ease;
  margin-right: 0%;
}

.arrow-dash {
  position: absolute;
  top: 0;
  left: 1%; /* Center the line above the arrow */
  width: 2px; /* Change the line thickness as needed */
  height: 40px; /* Adjust this value based on the arrow height */
  background-color: #fff; /* Change the color as needed */
  transform: translateX(8px); /* Center the line above the arrow */
}

.arrow-triangle {
  position: relative;
  top: 21px;
  border-color: #fff;
  border-style: solid;
  border-width: 2px 2px 0 0;
  height: 1rem;
  left: 50%;
  position: absolute;
  transform: rotate(135deg);
  width: 1rem;
}

.arrow.visible {
  opacity: 1;
  transform: translateY(0);
}


/* Mobile */

.nav-mobile {
  width: 30%;
  display: none;
  justify-content: space-between;
  justify-content: flex-end;
}

.nav-mobile ul {
  list-style-type: none;
  display: flex;
  justify-content: space-between;
}

.nav-mobile ul img {
  height: 20px;
  margin: 0 10px;
}

.nav-mobile .social {
  transition: opacity 1s cubic-bezier(0.215, 0.61, 0.355, 1);
  opacity: 0.2;
}

.nav-mobile .social:hover {
  opacity: 1;
}

.nav-mobile .scrolled {
  transition: opacity 1.4s cubic-bezier(0.215, 0.61, 0.355, 1);
  opacity: 1;
}

.nav-mobile .scrolled:hover {
  opacity: 0.2;
}

@media only screen and (max-width: 769px) {
  .header {
    width: 80%;
  }

  /* .header .logo {
    opacity: 1;
  } */

  .intro-container {
    margin: 10rem auto 0rem;
    width: 80%;
  }

  .intro {
    margin-right: 0%;
  }

  #intro-text {
    font-size: 36px;
  }

  .arrow {
    margin-right: 10%;
  }

  .history-container {
    flex-wrap: wrap;
    width: 80%;
  }

  .history-container p {
    font-size: 24px;
    margin-bottom: 2em;
  }

  .history-container .col-2 {
    font-size: 16px;
    margin-left: 0%;
    width: 100%;
    margin-top: 2.5rem;
  }

  .history-container .col-2 .heading {
    font-size: 14px;
  }

  .history-container .col-2 .heading:not(:first-child) {
    margin-top: 2.7rem;
  }

  .history-container .col-2 .role-time {
    margin-top: 0.2em;
    opacity: 0.9;
    font-size: 12px;
  }

  .nav,
  .contact {
    display: none;
  }

  .nav-mobile {
    display: flex;
  }

  .circle {
    display: none;
  }
}
